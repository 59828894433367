import { useEffect, useRef } from "react";

export const useResizeObserver = (callback) => {
  const reference = useRef();

  useEffect(() => {
    const element = reference.current;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        callback(entry.contentRect);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [callback]);

  return reference;
};
