const DELIMITER = "&";
const EQUALS = "=";
const QUERY = "?";
const CONTROL_LENGTH = 1;

export default function arrayToQueryParameters(queries) {
  if (queries.length < CONTROL_LENGTH) return "";

  const length = queries.length - CONTROL_LENGTH;

  var finalQuery = "";

  queries.forEach((query, index) => {
    finalQuery += query.key + EQUALS + query.value;

    if (length !== index) {
      finalQuery += DELIMITER;
    }
  });

  return QUERY + finalQuery;
}
