export const FontTitleStyle = {
  fontWeight: 600,
  fontSize: "24px",
  color: "#000000",
};

export const FontDescriptionStyle = {
  fontWeight: 400,
  fontSize: "16px",
  marginTop: "4px",
  color: "#6b7280",
};
