const Style = {
  title: {
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "0px",
    marginBottom: "0px",
  },
};

export default function AppBarTitle(props) {
  const title = props.title;
  return (
    <p className="global-font" style={Style.title}>
      {title}
    </p>
  );
}
