import * as React from "react";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PersonDrawerHeader from "../header/PersonDrawerHeader";
import AppBarTitle from "../fonts/AppBarTitle";
import PageItemList from "./PageItemList";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useResizeObserver } from "../ext/ReferenceExtensions";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function PageContainer() {
  // Redux
  const { title } = useSelector((state) => state.title);

  // State hooks
  const [open, setOpen] = useState(false);
  const [headerSize, setHeaderSize] = useState({ width: 0, height: 0 });

  // Resize listeners
  const headerReference = useResizeObserver((rect) => {
    if (headerSize.width !== rect.width || headerSize.height !== rect.height) {
      setHeaderSize({ width: rect.width, height: rect.height });
    }
  });

  // Listeners
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{ bgcolor: "black" }}
      >
        <Toolbar ref={headerReference}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <AppBarTitle title={title} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <PersonDrawerHeader
          handleCloseDrawer={handleDrawerClose}
          isDrawerOpen={open}
          topBarHeight={headerSize.height}
        />
        <Divider />
        <PageItemList isDrawerOpen={open} />
      </Drawer>
      <Box
        component="main"
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            height: `${headerSize.height}px`,
          }}
        />
        <Outlet />
      </Box>
    </Box>
  );
}
