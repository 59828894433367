import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { booksTitle } from "./TitlePages";
import { changeTitle } from "../state/titleSlicer";
import { GridCardImage } from "../cards/GridCardImage";
import { openNewTab } from "../ext/WindowExtensions";
import { Button, Grid2 } from "@mui/material";
import { ButtonStyle } from "../../theme/ButtonStyles";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

export default function BooksPage() {
  const title = "Guia do investidor iniciante:";
  const description =
    "Fundamentos, estratégias, títulos e práticas para iniciantes no mundo dos investimentos.";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(booksTitle));
  });

  return (
    <div style={Styles.container}>
      <Grid2
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <GridCardImage
          title={title}
          description={description}
          image={require("../../assets/book.png")}
          navComponent={
            <Button
              variant="outlined"
              sx={ButtonStyle}
              onClick={() => {
                openNewTab(
                  "https://drive.google.com/drive/folders/1Vq_JUJnVFip3tt9uLzfw5s1T_qtyztMG?usp=drive_link"
                );
              }}
            >
              Download
            </Button>
          }
        />
      </Grid2>
    </div>
  );
}
