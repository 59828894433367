import { FontTitleStyle } from "../../theme/FontStyles";
import { openNewTab } from "../ext/WindowExtensions";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";

export default function GridCardButton(props) {
  return (
    <div className="global-font-div">
      <Card variant="outlined" sx={{ maxWidth: 345, height: "100%" }}>
        <CardActionArea
          sx={{ maxWidth: 345, height: "100%" }}
          onClick={() => {
            openNewTab(props.link);
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: "200px" }}
            image={props.image}
          />
          <CardContent>
            <div>
              <div style={FontTitleStyle}>{props.title}</div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
