import { Button } from "@mui/material";
import { openNewTab } from "../ext/WindowExtensions";
import { RoundedButtonStyle } from "../../theme/ButtonStyles";

const Styles = {
  buttonsCotainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "24px",
  },
  buttonSize: {
    height: "24px",
    width: "24px",
    borderRadius: "14px",
  },
  buttonSpacing: {
    marginRight: "8px",
  },
};

export default function PresentationHeaderSocial() {
  return (
    <div style={Styles.buttonsCotainer}>
      <Button
        variant="outlined"
        sx={RoundedButtonStyle}
        onClick={() => {
          openNewTab("https://github.com/GabrielBrasileiro");
        }}
        style={Styles.buttonSpacing}
      >
        <img
          style={Styles.buttonSize}
          src={require("../../icons/github.png")}
          alt=""
        ></img>
      </Button>
      <Button
        variant="outlined"
        sx={RoundedButtonStyle}
        onClick={() => {
          openNewTab("https://www.linkedin.com/in/gabrielbrasileiro/");
        }}
      >
        <img
          style={Styles.buttonSize}
          src={require("../../icons/linkedin.png")}
          alt=""
        ></img>
      </Button>
    </div>
  );
}
