import * as React from "react";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTitle } from "../state/titleSlicer";
import { presentationTitle } from "./TitlePages";
import PersonHeaderPresentation from "../header/PersonHeaderPresentation";
import { openNewTab } from "../ext/WindowExtensions";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Button } from "@mui/material";
import { ButtonStyle } from "../../theme/ButtonStyles";

const Styles = {
  timelineContainer: {
    marginTop: "32px",
    marginBottom: "56px",
    marginRight: "5%",
    marginLeft: "5%",
  },
  buttonContainers: {
    marginTop: "24px",
    marginBottom: "24px",
    display: "flex",
    flexDirection: "row",
  },
  buttonRight: {
    marginLeft: "12px",
  },
  timelineTitle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#9ca3af",
  },
  timelineContent: {
    fontWeight: "400",
    color: "#6b7280",
  },
  timelineDot: {
    padding: 0,
    width: "30px",
    height: "30px",
    borderWidth: 0,
    boxShadow: "none",
    backgroundColor: "white",
  },
  timelineConnector: {
    width: "1px",
    backgroundColor: "#E5E7EB",
  },
  timelineFitstButton: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  timelineBottom: {
    marginBottom: "24px",
  },
};

function TimelineImage(props) {
  return (
    <div>
      <TimelineDot style={Styles.timelineDot}>
        <img
          src={props.image}
          alt="Event"
          style={{ width: "100%", height: "100%", borderRadius: "50%" }}
        />
      </TimelineDot>
    </div>
  );
}

export default function PresentationPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(presentationTitle));
  });

  return (
    <div>
      <PersonHeaderPresentation />
      <div className="global-font-div global-font-spaccing">
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
          style={Styles.timelineContainer}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/porto.png")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>Fevereiro de 2023 - Atual</p>
              <p>Porto</p>
              <strong>Staff Software Engineer</strong>
              <p style={Styles.timelineContent}>
                Engenheiro de Software com especialidade mobile.
                <br />
                <br />
                Atuação a frente das squads (Histórico):
                <br /> - Autenticação;
                <br /> - Cross;
                <br /> - Plataforma;
                <br /> - Analytics;
                <br /> - Engajamento.
                <br />
                <br /> Global:
                <br /> - Gerenciamento da distribuição de versões;
                <br /> - Loja de aplicativos;
                <br /> - Negócio;
                <br /> - CI/CD;
                <br /> - Ofuscação.
                <br />
                <br /> Local:
                <br /> Atualmente com uma atuação mais direta no time de
                plataforma, realizando orientação global e local de estruturas
                criadas para todo o escopo de atuação do projeto.
                <br />
                <br /> Atuações diretas em features:
                <br />
                Seguro celular, CRM, CPCP (Crédito Pessoal com Cartão Porto),
                engenharia global, sessão, pipeline (CI/CD), Cirque Du Soleil.
              </p>
              <Button
                style={Styles.timelineFitstButton}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://play.google.com/store/apps/details?id=br.com.portoseguro.experienciacliente.mundoporto&hl=pt_BR"
                  );
                }}
              >
                Conheça o SuperApp da Porto
              </Button>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/porto.png")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Abril de 2021 - Fevereiro de 2023
              </p>
              <p>Porto</p>
              <strong>Specialist Software Engineer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Engenheiro de Software com especialidade android.
                <br />
                <br />
                Features: <br />
                Security; Plataform; Cards; Baking; Facial Biometry; Token;
                Baking Security; Login; Core; Support; Internal libraries.
                <br />
                <br />
                Skills: <br />
                Clean Architecture; Hexagonal; Kotlin, Design Patterns (Facade,
                Observer, Factory, Strategy, Builder, Adapter), Refatoração; CI;
                Jenkins; Jacoco; Detekt; Groovy; Modularização; Koin; Mockk;
                Mockito; Coroutines; Flow; Camera; Annotations; Gradle; Code
                Smells, Test Smells; Unit Tests; Instrumented Tests; Robot
                Pattern.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage
                image={require("../../icons/universodoandroid.jpeg")}
              />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>Janeiro de 2013 - Momento</p>
              <strong>Universo do Android</strong>
              <p>CEO e Dono</p>
              <p style={Styles.timelineContent}>
                Canal e Site criados com o objetivo de ensinar as pessoas a
                realizarem processos diversificados nos dispositivos Android.
                <br />
                <br />
                Recentemente foi iniciado um projeto no canal/site para ensinar
                aos usuários a desenvolverem APPs para Android.
                <br />
                <br /> Atualmente desenvolvendo APP para Android com o intuito
                de ajudar aos usuários a utilizarem seu Android de forma
                simplificada Desenvolvedor do APP Udroid que atualmente se
                encontra em versão Alpha.
              </p>
              <div style={Styles.buttonContainers}>
                <Button
                  variant="outlined"
                  sx={ButtonStyle}
                  onClick={() => {
                    openNewTab("https://www.youtube.com/c/universodoandroid");
                  }}
                >
                  Canal
                </Button>
                <Button
                  variant="outlined"
                  style={Styles.buttonRight}
                  sx={ButtonStyle}
                  onClick={() => {
                    openNewTab("http://www.universodoandroid.com");
                  }}
                  color="gray"
                >
                  Site
                </Button>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/picpay.jpeg")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Dezembro de 2019 - Abril de 2021
              </p>
              <p>PicPay</p>
              <strong>Android Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Engenheiro de Software Android.
                <br />
                <br /> Trabalhando com Clean Architecture, Java, Kotlin, Design
                Patterns (Facade, Observer, Factory, Strategy...), code smells,
                refatoração, CI, modularização, koin, RXJava2, annotations,
                deeplinks, gradle, testes e testes instrumentados, Firebase.
                <br />
                <br />
                Features trabalhadas: <br />
                PIX, Core, Business Core, Deeplinks, Presentes, Troca de senha,
                Renovação Cadastral, Deeplink Store, Identidade, Cadastro
                Dinâmico, Extrato, Tutorial.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage
                image={require("../../icons/zupinnovation.jpeg")}
              />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Agosto de 2019 - Novembro de 2019
              </p>
              <p>Zup Innovation</p>
              <strong>Android Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Densenvolvedor Android
                <br />
                <br />
                Desenvolvedor Android Trabalhando com componetização, MVVM e a
                Clean Architecture. Desenvolvendo como consultor para um grande
                banco espanhol.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/handtalk.jpeg")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Novembro de 2018 - Agosto de 2019
              </p>
              <p>Hand Talk</p>
              <strong>Mobile Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Aplicativos Android e IOS nativos (Java, Kotlin e Swift) OKR
                (Objetivos e Principais Resultados) Líder.
                <br />
                <br />
                Eu tive a experiência de desenvolver com Java, Kotlin e Swift.
                Exeperiência com MVP (Model View Presenter), aplicando o SOLID
                para o código ter uma melhor legibilidade. Em 60% do tempo
                trabalhei com Swift.
                <br />
                <br />
                Na gestão trabalhando com os OKRs, elaborei estratégias baseadas
                nas estatísticas adquiridas com as métricas do aplicativo.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/stant.jpeg")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Março de 2018 - Novembro de 2018
              </p>
              <p>Stant</p>
              <strong>Android Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Desenvolvimento Nativo com Java e Kotlin.
                <br />
                <br />
                Experiência com VIPER, MVP e Componentização. Em uma das tarefas
                desenvolvi parte de uma lib para o processamento de imagens.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/ifal.jpeg")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Março de 2018 - Novembro de 2018
              </p>
              <p>Instituto Federal de Alagoas</p>
              <strong>Desenvolvedor de Jogos e Designer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Extensionista em criação de jogos
                <br />
                <br />
                Projeto de extensão realizado com o objetivo de desenvolver um
                jogo com imagens adaptas para crianças altistas.
                <br />
                <br />
                Tecnologias:
                <br /> Lua, CoronaSDK, Android, iOS, Figma.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineImage image={require("../../icons/bwn.png")} />
              <TimelineConnector style={Styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <p style={Styles.timelineTitle}>
                Setembro de 2017 - Junho de 2018
              </p>
              <p>BWN Aplicativos</p>
              <strong>Estagiário</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Desenvolvedor Android e Beck-End com Java.
                <br />
                <br />
                Tecnologias:
                <br /> Atuação com PostgreSQL, SQL, JasperReports, JSF, Tomcat.
              </p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
}
