import { useDispatch } from "react-redux";
import { changeTitle } from "../state/titleSlicer";
import { calculatorTitle } from "./TitlePages";
import { useEffect } from "react";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

export default function CalculatorPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(calculatorTitle));
  });

  return (
    <div style={Styles.container}>
      <p className="global-font">
        Calculadoras para auxílio nos investimentos estarão disponíveis em
        breve.
      </p>
    </div>
  );
}
