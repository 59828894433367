import personImage from "../../assets/person.png";

const Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  textTitle: {
    width: "80%",
    marginTop: "8px",
    whiteSpace: "wrap",
    textAlign: "center",
    marginBottom: "4px",
  },
  textDescription: {
    width: "80%",
    whiteSpace: "wrap",
    textAlign: "center",
    marginBottom: "24px",
  },
};

const DescriptionHeader = (props) => {
  const isDrawerOpen = props.isDrawerOpen;

  if (isDrawerOpen) {
    return (
      <div style={Styles.textContainer}>
        <h4 className="global-font" style={Styles.textTitle}>
          Gabriel Brasileiro
        </h4>
        <div className="global-font" style={Styles.textDescription}>
          Staff Software Engineer
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export const PersonHeader = (props) => {
  const isDrawerOpen = props.isDrawerOpen;

  return (
    <div style={Styles.container}>
      <img
        style={{
          width: isDrawerOpen ? "50%" : "35%",
          borderRadius: "50%",
          overflow: "hidden",
          marginTop: isDrawerOpen ? "24px" : "16px",
          marginBottom: isDrawerOpen ? "0px" : "16px",
        }}
        src={personImage}
        alt=""
      />
      <DescriptionHeader isDrawerOpen={isDrawerOpen} />
    </div>
  );
};
