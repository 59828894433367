import { Card, CardContent, CardMedia } from "@mui/material";
import { FontDescriptionStyle, FontTitleStyle } from "../../theme/FontStyles";

const Styles = {
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  buttonContent: {
    marginTop: "20px",
    alignSelf: "flex-end",
  },
};

export function GridCardImage(props) {
  const title = props.title;
  const description = props.description;
  const image = props.image;
  const navigationComponent = props.navComponent;

  return (
    <div>
      <Card variant="outlined" sx={{ maxWidth: 300, height: "100%" }}>
        <CardMedia sx={{ height: 320 }} image={image} />
        <CardContent style={{ paddingBottom: 16 }}>
          <div
            className="global-font global-font-spaccing"
            style={Styles.cardContent}
          >
            <div>
              <div style={FontTitleStyle}>{title}</div>
              <div style={FontDescriptionStyle}>{description}</div>
            </div>
            <div style={Styles.buttonContent}>{navigationComponent}</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
