/**
 * Default button style.
 */
export const ButtonStyle = {
  variant: "outlined",
  color: "black",
  borderColor: "#e5e7eb",
  textTransform: "none",
  "&:hover": {
    borderColor: "#c1c4ca",
    backgroundColor: "#f3f4f6",
  },
};

/**
 * Default rounded style.
 */
export const RoundedButtonStyle = {
  borderColor: "#e5e7eb",
  borderRadius: "20px",
  color: "#e5e7eb",
  "&:hover": {
    borderColor: "#c1c4ca",
    backgroundColor: "#f3f4f6",
  },
};
